import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SurveyWrapperService } from 'app/shared/surveys/survey-wrapper/survey-wrapper.service';
import { UtilService } from 'app/shared/util.service';
import { FollowupOnsite } from 'app/states/followup-onsite/followup-onsite.actions';
import { StoreCurrentSurvey } from 'app/states/survey/survey.actions';
import { CurrentSurvey } from 'app/states/survey/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IHTTPResponse } from 'app/core-lib/interfaces/http-response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SurveyFilter } from 'app/new-fs/followup-onsite/models/SurveyFilters.model';
import { BreakpointService } from '../breakpoints/breakpoint.service';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  readonly COLLECT_ID = 5;
  readonly FOLLOWUP_ID = 1;
  listTypes = [];
  selectedTableType = this.isMobile || this.isTablet ? 'extended' : 'compact';
  tableTypes = ['compact', 'extended'];

  constructor(
    private utilService: UtilService,
    private surveyWrapperService: SurveyWrapperService,
    private store: Store,
    private http: HttpClient,
    private breakpointService: BreakpointService,
  ) { }

  getCustomerSurveys(chain: any, inputProductId: any, showOptionAll = true, inputCustomer = null, emitStoreSurveys = true){
    const currentCustomerId = (inputCustomer) ? inputCustomer.id : this.utilService.currentHotel.id;
    const ids = chain.length > 0 ? chain.map((customer: any)=>customer.id).join(',') : currentCustomerId;
    const productId = inputProductId === this.COLLECT_ID ? this.FOLLOWUP_ID : inputProductId;
    this.surveyWrapperService.getChainCustomerSurveys(ids,productId).subscribe((response:any)=>{
      const listSurveys = response || [];
      if (emitStoreSurveys) this.store.dispatch(new FollowupOnsite.StoreSurveys(listSurveys));
      let selectedSurvey;
      if(!showOptionAll){
        selectedSurvey = (chain.length > 0) ? listSurveys[0].type.id : listSurveys[0].id;
      } else selectedSurvey = 0;

      this.setSurveyTypes(listSurveys);
      this.setCurrentSurvey(selectedSurvey, listSurveys, chain);
    })
  }

  setCurrentSurvey(filter:number, listSurveys, chain){
    const isChain = (chain.length > 0);
    const surveys = filter === 0 ? listSurveys : this.selectSurveyByTypeOrId( isChain ? 'type' : 'id', filter, listSurveys);
    const ids = surveys.map((x: any) => x['id']).join(',');
    const currentSurvey = new CurrentSurvey(ids, surveys);
    this.store.dispatch(new StoreCurrentSurvey(currentSurvey));
    this.utilService.setCurrentSurvey(ids,surveys);
  }

  setCurrentSurveyV2(selectedSurveys) {
    const ids = selectedSurveys.map(survey => survey.id).join(',');
    const currentSurvey = new CurrentSurvey(ids, selectedSurveys);
    this.store.dispatch(new StoreCurrentSurvey(currentSurvey));
    this.utilService.setCurrentSurvey(ids, selectedSurveys);
  }

  selectSurveyByTypeOrId(condition: 'id' | 'type', filter, listSurveys){
    if(condition==='type'){
      return listSurveys.filter(x=>x.type.id===filter);
    }else{
      return [listSurveys.find(x=>x.id===filter)];
    }
  }

  setSurveyTypes(listSurveys){
    this.listTypes = listSurveys.reduce((acc, curr) => {
      const id = curr.type.id;
      const version = curr.version;
      const translates = curr.translates;

      const survey = {
        type: id,
        version,
        translates,
      }

      const surveyFound = acc.find(surv => surv.type === id);
      if (!surveyFound) {
        acc = [...acc, survey]
      }
      return acc;
    }, [])
  }

  exportPDF(body) {
    const { survey, language, guest } = body;
    const date = survey?.answer?.date.split(' ')[0];
    const params: HttpParams = new HttpParams()
      .set('surveyId', survey.id)
      .set('surveyCode', survey?.answer?.id)
      .set('language', language)
      .set('startDate', date)
      .set('endDate', date)
      .set('guestId', guest?.id);
    const url = `${environment.applicationRenderSurveyURL}`;
    return this.http.post(url, body, { params, responseType: 'blob' });
  }

  getSurveyTypes(customerId: string, payload: any): Observable<SurveyFilter> {
    const url = `${environment.followupPath}customers/${customerId}/filters`;
    const params: HttpParams = new HttpParams()
      .set('start_date', payload.start_date)
      .set('end_date', payload.end_date)
      .set('product_id_list', payload.product_id_list)
      .set('language_id', payload.languageId)
      .set('guest_name', payload.guest_name);

    return this.http.get<IHTTPResponse>(url, { params }).pipe(map((response: IHTTPResponse) => response.data));
  }

  get isMobile() {
    return this.breakpointService.isMobile();
  }

  get isTablet() {
    return this.breakpointService.isMinorThanMd();
  }
}
