import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationsData } from '../models/communications';
import { environment } from '../../../../environments/environment';
import { IHTTPResponse } from 'app/core-lib/interfaces/http-response';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private updateNews$ = new Subject();

  constructor(private http: HttpClient) {}


  getNotificationsData(payload: { page: number; pageSize?: number }, url: string): Observable<NotificationsData> {
    const { page, pageSize } = payload;
    const params = new HttpParams().set('page', page).set('page_size', pageSize || 10);
    return this.http.get<NotificationsData>(url, { params });
  }


  listenUpdateOnNews() {
    return this.updateNews$.asObservable();
  }

  emitUpdateOnNews() {
    this.updateNews$.next();
  }

  changeNotificationStatus(url, requestParams = null) {
    const params = requestParams
      ? new HttpParams().set('customer_id', requestParams?.customerId).set('viewed_time', requestParams?.viewedTime)
      : null;
    return this.http.patch(url, null, { params });
  }

  markAllAsRead(userId: number, notificationType = 'ALL') {
    const url = `${environment.notificationsPath}manager/notifications/users/${userId}/status/READ`;
    const params = new HttpParams().set('notification_type', notificationType);
    return this.http.patch(url, {}, { params });
  }

  getNotificationsDataByDates(
    userId: number,
    langId: number,
    payload: { page: number; pageSize: number; startDate: string; endDate: string },
    isAlerts = true,
  ): Observable<NotificationsData> {
    const url = `${environment.notificationsPath}notification-${
      isAlerts ? 'alerts' : 'news'
    }/users/${userId}/language/${langId}/dates`;
    const { page, pageSize, startDate, endDate } = payload;

    const params = new HttpParams()
      .set('page', page)
      .set('page_size', pageSize)
      .set('start_date', startDate)
      .set('end_date', endDate);
    return this.http.get<NotificationsData>(url, { params });
  }

  pauseNotifications(userId: number, time: number): Observable<any> {
    const url = `${environment.notificationsPath}users/${userId}/pause-push`;
    const body = {
      flag_active: true,
      paused_time: time,
    };

    return this.http.put<any>(url, body);
  }

  getChannels(customerId: number, userId: number): Observable<IHTTPResponse> {
    const url = `${environment.notificationsPath}pub-sub/customers/${customerId}/users/${userId}/channels`;
    return this.http.get<IHTTPResponse>(url);
  }
}
