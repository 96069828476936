import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { TranslateObjectPipe } from 'app/shared/pipe';
import { CoreLibCreateCaseModalComponent } from 'app/core-lib/dialogs/create-case-modal/create-case-modal.component';
import { CasesService } from 'app/shared/cases';
import { SurveyService } from 'app/features/surveys/surveys.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { UtilService } from 'app/shared/util.service';
import { Store } from '@ngxs/store';
import { UsersState } from 'app/states/users/users.state';
import { CustomersState } from 'app/states/customers/customers.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CoreLibLimitCasesModalComponent } from 'app/core-lib/dialogs/limit-cases/limit-cases.component';
import { ConfigCasesService } from 'app/cases/services/config-cases.service';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { AlertService } from 'app/core-lib/services/alerts/alert.service';
import { CoreLibCasesAlertsComponent } from 'app/core-lib/alerts/cases-alerts/cases-alerts.component';
import { D } from '@angular/cdk/keycodes';

@Injectable({
  providedIn: 'root'
})
export class CreateCaseDialogService {

  readonly UPSELL_LINK = {
    'es': 'https://bit.ly/35og5e4',
    'pt': 'https://bit.ly/3sdopqh',
    'en': 'https://bit.ly/3h6pgCO',
  }
  readonly UPSELL_LINK_ADMIN = {
    'es': 'https://bit.ly/3h87izJ',
    'pt': 'https://bit.ly/3scXF9h',
    'en': 'https://bit.ly/3IhkBtw',
  };
  readonly CLASSIFICATION_LANGS = {
    'es': 'clasification_text',
    'en': 'clasification_text_en',
    'pt': 'clasification_text_pt'
  }

  public onSuccessCreatedCase$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  monthlyLimit = 0;

  constructor(
    private modalService: ModalService,
    private casesService: CasesService,
    private surveyService: SurveyService,
    private translateObjectPipe: TranslateObjectPipe,
    private utilService: UtilService,
    private store: Store,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private configCasesService: ConfigCasesService,
    private titleCasePipe: TitleCasePipe,
    private alertService: AlertService,
  ) { }

  // assigned = 'review', 'nps', 'prestay'
  // item = object according to product
  openModal(currentUser, associated = null, item = null, product = null, detectedCase = null) {
    this.fetchData(currentUser, associated, item, product, detectedCase)
  }

  fetchData(currentUser, associated, item, product, detectedCase) {
    const currentCustomerId = this.store.selectSnapshot(CustomersState).currentCustomer.id;
    const currentLanguage =
      this.store.selectSnapshot(DefaultLanguageState).languageId || this.utilService.getCurrentLanguageId();
    forkJoin({
      areas: this.fetchAreas(currentCustomerId, currentLanguage),
      baseCases: this.fetchBaseCases(),
      onsiteGuests: this.fetchOnsiteGuests(currentCustomerId),
    }).subscribe(({ areas, baseCases, onsiteGuests }: { areas: any; baseCases: any; onsiteGuests: any }) => {
      const config = {
        data: {
          noCloseOnBackdropClick: true,
          currentUser,
          types: baseCases.case_types,
          priorities: baseCases.priorities,
          productItemType: baseCases.product_item_type,
          states: baseCases.states,
          users: this.store.selectSnapshot(UsersState.currentUsers),
          translatesPipe: {
            types: this.displayFnTypes,
            priorities: this.displayFnPriorities,
            chips: this.displayChipsFn,
          },
          areas,
          associated,
          item,
          product,
          onsiteGuests,
          detectedCase,
        },
      };
      this.displayModal(config, currentCustomerId);
    });
    this.configCasesService.getCasesLimit(currentCustomerId).subscribe((result) => {
      if (result) {
        this.monthlyLimit = result?.monthly_limit || 0;
      }
    });
  }

  displayModal(config, customerId) {
    const classes = [
      'overlay-panel',
      '!tw-h-auto',
      'no-padding',
      '!tw-overflow-auto',
    ];

    classes.push(config.data?.item?.answer?.detectedCase || config.data?.item?.detected_cases ? 'lg' : 'md');

    const dialog = this.modalService.open(CoreLibCreateCaseModalComponent, config, classes)
    dialog.afterClosed().subscribe(valuesForm => {
      if ( valuesForm && valuesForm !== 'discard') {
        this.handleSubmit(valuesForm, customerId, config.data.currentUser)
      }
    })
  }

  handleSubmit(valuesForm, customerId, currentUser) {
    this.casesService.save(valuesForm, customerId).subscribe((response: any) => {
        const data = { translateKey: 'cod', translateValue: response.public_id, url: response.url_to_share, type: 'success' };
        this.showNotification(data, CoreLibCasesAlertsComponent);
    }, (error) => {
        if (error.code === 4007) {
          this.limitCasesError(currentUser);
        } else {
          const data = { type: 'success' };
          this.ga('new-form', 'save-form', error['id'])
          this.showNotification(data);
        }
    }, () => this.onSuccessCreatedCase$.next(true));
  }

  showNotification(data, component = null) {
    this.alertService.handleAlert(
      'cases.commons.success_create',
      9000,
      'bottom',
      'end',
      'snackbar-panel-success-user-config',
      component ? component : null,
      data ? data : null,
    );
  }

  openSnackBar(snackbarOptions, message) {
    this.translate.get(message)
      .subscribe(text => {
        this.snackbar.open(text, 'x', snackbarOptions);
      });
  }

  limitCasesError(currentUser) {
    const config = {
      data: {
        user: currentUser,
        upsellLink: this.getUpsellLink(currentUser.superAdmin, currentUser.admin),
        monthlyLimit: this.monthlyLimit,
        hotel: this.store.selectSnapshot(CustomersState.currentCustomer)
      }
    }
    const classes = [
      'overlay-panel',
      'no-padding',
      '!tw-h-fit'
    ]
    this.modalService.open(CoreLibLimitCasesModalComponent, config, classes)
  }


  fetchAreas(customerId: number, languageId: number) {
    return this.casesService.getAreas(customerId, languageId);
  }

  fetchBaseCases() {
    return this.casesService.getBase();
  }

  fetchOnsiteGuests(customerId: number) {
    return this.casesService.getOnsiteGuests(customerId);
  }

  displayFnTypes = (option) => {
    return this.translateObjectPipe.transform(option.translates, 'name')
  }

  displayFnPriorities = (option) => {
    return this.titleCasePipe.transform(
      this.translateObjectPipe.transform(option.translates, 'name')
    )
  }

  displayChipsFn = (option) => {
    if ( this.utilService.currentLanguage === 'es' )
      return option.clasification_text;
    if ( this.utilService.currentLanguage === 'en' )
      return option.clasification_text_en;
    if ( this.utilService.currentLanguage === 'pt' )
      return option.clasification_text_pt;
  }

  mapAreas(areas_translates, areas) {
    return areas_translates.reduce((prev, curr) => {
      const considerArea = areas.find(area => area.id === curr.area_id);
      if (!considerArea) {
        return [
          ...prev
        ]
      }
      const existArea = prev.findIndex(area => area.id === curr.area_id);
      const key = this.CLASSIFICATION_LANGS[curr?.language?.code];
      if ( existArea >= 0 ) {
        prev[existArea][key] = curr.name;
        return [
          ...prev
        ]

      } else {
        return [
          ...prev,
          {
            id: curr.area_id,
            [key]: curr.name
          }
        ];
      }
    }, []);
  }

  ga(action:string, label?:string, value?:number) {
    const gaEventAction = `${action}-${this.currentPath}`
    this.utilService.ga('cases', gaEventAction, label, value);
  }

  get currentPath() {
    const possitblePaths: any[] = ['user', 'history', 'active'];
    const currentPath = this.activatedRoute.routeConfig?.path;

    if (possitblePaths.includes(currentPath)) return currentPath;

    return 'product';
  }

  getUpsellLink(userSuperAdmin, userAdmin) {
    const currentLang = this.utilService.getCurrentLanguage()
    return `${userSuperAdmin || userAdmin ? this.UPSELL_LINK_ADMIN[currentLang] : this.UPSELL_LINK[currentLang]}`;
  }

  isCaseCreated() {
    return this.onSuccessCreatedCase$.asObservable();
  }

}
