// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { environment } from './environments/environment';

const config =  {
    API: {
        GraphQL: {
          endpoint: environment.appSyncGraphqlEndpoint,
          region: 'us-west-1',
          defaultAuthMode: 'apiKey',
          apiKey: environment.appSyncApiKey
        }
    }
};

export default config;