import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { UserEntrieLog } from 'app/shared/model';
import { Observable } from 'rxjs';
import { UtilService } from 'app/shared/util.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  showChainDataKey = 'showChainDataEntries';

  constructor(
    private http:HttpClient,
    private utilService: UtilService,
  ) { }

  usersInCustomer(customerId){
    const url=`${environment.customerUser}/basic`;
    const params:HttpParams= new HttpParams()
      .set("customer_id",customerId)
      .set("access",'true');

    return this.http.get(url, {params: params})
    .pipe(
      map(response=>response)
    );
  }

  base() {
    return this.http.get(`${environment.customerUser}/base`)
    .pipe(
      map(response=>response)
    );
  }


  getUser(user_id:number) {
    return this.http.get(`${environment.customerUser}/${user_id}`)
    .pipe(
      map(response=>response)
    );
  }

  getUsers(customer_id){
    const params:HttpParams= new HttpParams()
    .set("customer_id",customer_id)
    return this.http.get(`${environment.customerUser}/`, {params : params})
    .pipe(
      map(response=>response)
    );
  }

  updateUser(user) {
    return this.http
      .put(`${environment.customerUser}/${user.id}`, user)
      .pipe(
        map( response => response )
      )
  }

  createUser(user) {
    return this.http
    .post(`${environment.customerUser}`, user)
    .pipe(
      map( response => response )
    )
  }

  deleteUser(id){
    let url = `${environment.customerUser}/${id}`;
    return this.http
      .delete(url)
      .pipe(
        map( response => response )
      )
  }

  getCustomerIds(showChain: boolean): string {
    let customerIds;
    if (showChain) {
      const innerChain = this.utilService.getInnerChain();
      const innerChainIds = innerChain && innerChain.map((hotel) => hotel.id) || [];
      customerIds = innerChainIds.join(',');
    } else {
      const currentCustomerId = this.utilService.currentHotel?.id || '';
      customerIds = currentCustomerId;
    }

    return customerIds;
  }

  getUsersEntries(customersIds: string, startDate: string, endDate: string): Observable<UserEntrieLog[]> {
    const url = `${environment.customerUser}/entries/list`;
    const params = new HttpParams()
      .set('customers_ids', customersIds)
      .set('start_date', startDate)
      .set('end_date', endDate);

    return this.http.get<UserEntrieLog[]>(url, { params });
  }

  getReportUserEntries(startDate: string, endDate: string, languageId: string) {
    const url = `${environment.reportsPath}customers/users/manager`;
    const customerIds = this.getCustomerIds(this.getShowChainDataEntries());
    const params = new HttpParams()
      .set('customers_ids', customerIds)
      .set('start_date', startDate)
      .set('end_date', endDate)
      .set('language_id', languageId);
    return this.http.get(url, { params });
  }

  setShowChainDataEntries(value: boolean): void {
    sessionStorage.setItem(this.showChainDataKey, JSON.stringify(value));
  }

  getShowChainDataEntries(): boolean {
    return JSON.parse(sessionStorage.getItem(this.showChainDataKey));
  }

  clearShowChainDataEntries(): void {
    sessionStorage.removeItem(this.showChainDataKey)
  }
}
