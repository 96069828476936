/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import { Client, generateClient, GraphQLResult } from "aws-amplify/api";
import { Observable } from "rxjs";

export type __SubscriptionContainer = {
  onMessagePosted: OnMessagePostedSubscription;
};

export type Message = {
  __typename: "Message";
  channel: string;
  content: string;
  type: string;
  sender: string;
  blocking: boolean;
};

export type PostMessageMutation = {
  __typename: "Message";
  channel: string;
  content: string;
  type: string;
  sender: string;
  blocking: boolean;
};

export type GetMessagesQuery = {
  __typename: "Message";
  channel: string;
  content: string;
  type: string;
  sender: string;
  blocking: boolean;
};

export type OnMessagePostedSubscription = {
  __typename: "Message";
  channel: string;
  content: string;
  type: string;
  sender: string;
  blocking: boolean;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  public client: Client;
  constructor() {
    this.client = generateClient();
  }
  async PostMessage(
    content: string,
    sender: string,
    channel: string,
    type: string,
    blocking: boolean
  ): Promise<PostMessageMutation> {
    const statement = `mutation PostMessage($content: String!, $sender: String!, $channel: String!, $type: String!, $blocking: Boolean!) {
        postMessage(
          content: $content
          sender: $sender
          channel: $channel
          type: $type
          blocking: $blocking
        ) {
          __typename
          channel
          content
          type
          sender
          blocking
        }
      }`;
    const gqlAPIServiceArguments: any = {
      content,
      sender,
      channel,
      type,
      blocking
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <PostMessageMutation>response.data.postMessage;
  }
  async GetMessages(channel: string): Promise<Array<GetMessagesQuery>> {
    const statement = `query GetMessages($channel: String!) {
        getMessages(channel: $channel) {
          __typename
          channel
          content
          type
          sender
          blocking
        }
      }`;
    const gqlAPIServiceArguments: any = {
      channel
    };
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <Array<GetMessagesQuery>>response.data.getMessages;
  }
  OnMessagePostedListener(
    channel: string
  ): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "onMessagePosted">>
  > {
    const statement = `subscription OnMessagePosted($channel: String!) {
        onMessagePosted(channel: $channel) {
          __typename
          channel
          content
          type
          sender
          blocking
        }
      }`;
    const gqlAPIServiceArguments: any = {
      channel
    };
    return this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    }) as any;
  }
}
