<div class="tw-flex tw-justify-between" *ngIf="progressRate">
  <div>
    <p class="tw-text-xl tw-font-semibold"[innerHTML]="'user_management.create_user.complete_your_profile' | translate"></p>
  </div>
  <div class="tw-flex tw-gap-1">
    <div class="tw-mb-5 tw-h-2 tw-rounded-full tw-bg-gray-200 tw-w-80 tw-mt-[10px]">
      <div class="tw-h-2 tw-rounded-full tw-bg-[#0F93CC] tw-w-[25%]"></div>
    </div>
    <p class="tw-text-xl">{{progressRate}}%</p>
  </div>
</div>

<form [formGroup]="form">
  <div class="tw-flex tw-flex-col md:tw-flex-row ">
    <div class=" tw-w-full  md:tw-w-1/2 tw-flex tw-flex-wrap">
      <ng-container *ngFor="let controlName of controls">
        <div [class]="'tw-flex tw-flex-col tw-flex-auto tw-mx-3 xl:tw-mx-5 tw-py-3 tw-w-full xl:tw-w-' + getColClass(controlName)" >
          <ng-container [ngSwitch]="controlName">
            <ng-container *ngSwitchCase="'language'">
              <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}<span class="tw-text-red-500">*</span></label>
              <select class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-pt-1" [formControlName]="controlName" [ngClass]="{'tw-border-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">
                <option *ngFor="let lang of languages" [ngValue]="lang.id">{{ 'languages.iso.' + lang.code | translate }}</option>
              </select>
            </ng-container>
  
            <ng-container *ngSwitchCase="controlName === 'department' || controlName === 'jobType' ? controlName : ''">
              <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}<span class="tw-text-red-500">*</span></label>
              <select class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-pt-1" [formControlName]="controlName" [ngClass]="{'tw-border-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">
                <option *ngFor="let item of (controlName === 'department' ? departments : (controlName === 'jobType') ? roles : rolesList)" [ngValue]="item.id">{{ 'models.user_profile.roles.' + item.id | translate }}</option>
              </select>
            </ng-container>
            <ng-container *ngSwitchCase="'role'">
              <div class="tw-flex tw-justify-between">
                <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}<span class="tw-text-red-500">*</span></label>
                <label class="tw-text-[#0F93CC] tw-text-xs tw-underline tw-cursor-pointer" (click)="openRolesActionsModal()">{{ 'user_management.create_user.whats_this' | translate }}</label>
              </div>
              <select class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-pt-1" [formControlName]="controlName" [ngClass]="{'tw-border-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">
                <option *ngFor="let item of (controlName === 'department' ? departments : (controlName === 'jobType') ? roles : rolesList)" [ngValue]="item.id">{{ 'models.user_profile.roles.' + item.id | translate }}</option>
              </select>
            </ng-container>
  
            <ng-container *ngSwitchCase="controlName === 'cellphone' || controlName === 'phone' ? controlName : ''">
              <label class="tw-text-main-text tw-text-xs">{{ 'user_management.create_user.personal_info.' + controlName | translate }}</label>
              <div class="tw-relative tw-w-full">
                <!-- Contenedor del botón y el input -->
                <div class="tw-flex tw-items-center tw-border tw-border-border-light-gray tw-rounded-md tw-bg-slate-100 tw-my-2">

                  <img 
                  [src]="imagesPath + (selectedCode?.code2?.toLowerCase() ?? 'xx') + '.svg'"
                  alt="Flag"
                  class="tw-h-4 tw-w-4 tw-mx-2"
                />

                  <!-- Input para búsqueda y selección del código de país -->
                <input
                class="tw-w-1/5 !tw-min-w-[61px] tw-h-[26px] tw-rounded-md tw-border-none tw-px-2 tw-bg-slate-100"
                type="text"
                formControlName="cellphoneCountryCode" 
                (focus)="toggleDropdown()"
                (input)="filterCodes()"
                placeholder="{{ 'commons.search_country_code' | translate }}"
                />
                
                <!-- Lista desplegable de códigos de país -->
                <ul *ngIf="isDropdownOpen" class="tw-absolute tw-top-full tw-left-0 tw-bg-white tw-shadow-lg tw-mt-1 tw-rounded-md tw-w-2/4 tw-max-h-60 tw-overflow-auto tw-p-0">
                  <li (click)="selectCode(null)" class="tw-flex tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 cursor-pointer tw-justify-between">
                    <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
                      <img [src]="imagesPath + 'xx.svg'" class="tw-h-4 tw-w-4 tw-mr-2">
                      <span> - </span>
                    </div>
                  </li>
                  <li *ngFor="let code of filteredCodes" (click)="selectCode(code)" class="tw-flex tw-items-center tw-px-4 tw-py-2 hover:tw-bg-gray-100 cursor-pointer tw-justify-between">
                    <div class="tw-flex tw-items-center tw-justify-center tw-w-full">
                      <img [src]="imagesPath + code?.code2?.toLowerCase() + '.svg'" alt="{{ code.country }} flag" class="tw-h-4 tw-w-4 tw-mr-2">
                      <span>{{ '+' + code?.code_phone }}</span>
                    </div>
                  </li>
                </ul>
                  
                  <!-- Input para el número de celular -->
                  <input class="tw-w-4/5 tw-h-[26px] tw-rounded-md tw-border-none tw-bg-white tw-px-2" type="text" [formControlName]="controlName" placeholder="{{'commons.enter_cellphone' | translate }}" />
                </div>
              </div>

              <!-- errores -->
              <div *ngIf="form.get('cellphone')?.hasError('invalidPhoneNumber')">
                <p class="tw-text-red-500">{{'user_management.errors.cellphone' | translate}}</p>
              </div>
              <div *ngIf="form.get('cellphone')?.hasError('invalidCountryCode')">
                <p class="tw-text-red-500">{{'user_management.errors.country_code' | translate}}</p>
              </div>
            </ng-container>
  
            <ng-container *ngSwitchCase="'birthdate'">
              <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}</label>
              <input type="date" class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font" lang="us-EN" onkeydown="return false" [formControlName]="controlName" placeholder="MM/DD/YYYY"/>
            </ng-container>

            
              <!-- TODO: se puede componetizar despues -->
              <ng-container *ngSwitchCase="'separator'">
                <div class="tw-flex tw-items-center tw-my-1">
                  <div class="tw-flex-grow tw-border-t tw-border-gray-300"></div>
                  <span class="tw-mx-4 tw-text-gray-500 tw-font-semibold">{{ 'commons.internal_use' | translate }}</span>
                  <div class="tw-flex-grow tw-border-t tw-border-gray-300"></div>
                </div>
              </ng-container>
              <!-- end -->

            <ng-container *ngSwitchCase="'hubspot_id'">
              <label class="tw-text-main-text tw-text-xs">{{ 'user_management.create_user.personal_info.' + controlName | translate }}</label>
              <input class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font" type="text" [formControlName]="controlName"/>
            </ng-container>

            <ng-container *ngSwitchCase="'cs_partner'">
              <label class="tw-flex tw-items-center tw-text-main-text tw-text-xs tw-h-full">
                <input type="checkbox" class="tw-mr-2 tw-rounded tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-w-6 tw-h-6 tw-text-blue-600 " [formControlName]="controlName"/>
                {{ 'user_management.create_user.personal_info.' + controlName | translate }}
              </label>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}<span class="tw-text-red-500">*</span></label>
              <input class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font" type="text" [formControlName]="controlName" [ngClass]="{'tw-border-detractors' : !form.get(controlName).valid && form.get(controlName).touched }"/>
            </ng-container>
          </ng-container>
  
        </div>
      </ng-container>
    </div>
    <div class="tw-w-full md:tw-w-1/2 tw-self-center tw-flex tw-flex-col tw-justify-center tw-items-center">
      <mh-profile-photo #profilePhoto [image]="data?.profile_photo || null" (changed)="changeProfilePhoto($event)"></mh-profile-photo>
      <div class="tw-ml-2" *ngIf="personalAdjusts">
        <a href="javascript:void(0)" class="tw-underline tw-text-sky-500 hover:tw-text-sky-600"
          [class.tw-opacity-50]="!form.valid" [class.tw-pointer-events-none]="!form.valid" (click)="profilePhoto.fileInput.nativeElement.click()">
          {{ 'user_management.upload_profile_photo' | translate }}
        </a>
      </div>
    </div>    
  </div>

  

  <div class="tw-flex tw-justify-center tw-mt-40 tw-gap-5" *ngIf="progressRate; else saveChanges">
    <mh-core-lib-button [isSecondary]="true" [isDisabled]="false" [text]="('commons.back' | translate)" (click)="updateIndexEmit(false)"></mh-core-lib-button>
    <mh-core-lib-button [isDefault]="true" [isDisabled]="!form.valid" [text]="('user_management.create_user.next' | translate)" (click)="updateIndexEmit(true)"></mh-core-lib-button>
  </div>
</form>

<ng-template #saveChanges>
  <div class="tw-flex tw-justify-center tw-gap-5">
    <mh-core-lib-button [isSecondary]="true" [text]="('commons.exit_not_save' | translate)" (click)="goToBack()"></mh-core-lib-button>
    <mh-core-lib-button [isSecondary]="true" [text]="('forms.edit_password' | translate)" (click)="editPassword()" customClass="tw-whitespace-nowrap !tw-px-2 " ></mh-core-lib-button>
    <mh-core-lib-button [isDefault]="true" [isDisabled]="!form.valid" [text]="('commons.save_changes' | translate)" (click)="emitUser()" [customClass]="'tw-bg-sky-500 hover:tw-bg-sky-500'" ></mh-core-lib-button>
  </div>
</ng-template>
