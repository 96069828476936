import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { Question } from '../model/question.model';
import { environment } from '../../../environments/environment';
import { IHTTPResponse } from 'app/core-lib/interfaces/http-response';
import { map } from 'rxjs/operators';
import { AnswerResponse, GuestAnswer, MailingData } from '../model';
import { ContactPayload } from 'app/core-lib/boxes/core-lib-answer-survey/core-lib-answer-survey.component';

@Injectable()
export class SurveyAnswerService {
  overallSurvey: any;
  allQuestions: Question[] = [];
  subjectSurveyDetail = new Subject<any>();

  constructor(private http: HttpClient) {}

  public translateComment(commentId, sourceLanguage, targetLanguage, productId, title, description) {
    const url = `${environment.customerPath}translate`;
    const body = {
      source_language_id: sourceLanguage,
      target_language_id: targetLanguage,
      product_id: productId,
      comment_id: commentId,
      title: title,
      description: description,
    };

    return this.http.post(url, body);
  }

  public deleteAnswerSurvey(id) {
    let url = `${environment.followupPath}surveys/answereds`;
    if (id !== '/') url = url + '/' + id;

    return this.http.delete(url);
  }

  getSurveyComments(surveyId: number, answerId: number) {
    const url = `${environment.surveysPath}${surveyId}/answered/${answerId}/comments`;
    return this.http.get(url);
  }

  setSurveyContacted(answerId: number) {
    const url = `${environment.surveysPath}answered/${answerId}/manage`;
    const body = {};
    return this.http.post(url, body);
  }

  getContactHistory(answeredSurveyId: number): Observable<IHTTPResponse> {
    const url = `${environment.followupPath}manage-answered-survey/answered/${answeredSurveyId}`;
    return this.http.get<IHTTPResponse>(url);
  }

  getSmartReplysurveyId(surveyId: number, answeredSurveyId: number): Observable<AnswerResponse> {
    const url = `${environment.followupPath}smart-replies/surveys/${surveyId}/answered-surveys/${answeredSurveyId}`;
    return this.http.get<IHTTPResponse>(url).pipe(map((response: IHTTPResponse) => response.data));
  }

  getMailingData(customerId: number, answeredSurveyId: number): Observable<MailingData> {
    const url = `${environment.followupPath}smart-replies/customers/${customerId}/answered-surveys/${answeredSurveyId}/mailing-data`;
    return this.http.get<IHTTPResponse>(url).pipe(map((response: IHTTPResponse) => response.data));
  }

  registerContactSurvey(
    answeredSurveyId: number,
    channelId: number,
    payload: ContactPayload,
  ): Observable<IHTTPResponse> {
    const url = `${environment.followupPath}manage-answered-survey/answered/${answeredSurveyId}/channel/${channelId}`;
    const body = {
      contact_subject: payload.subject,
      contact_minute: payload.description || null,
      guest_id: payload.guest_id || null,
      guest_mail: payload.guest_email || null,
    };
    return this.http.post<IHTTPResponse>(url, body);
  }

  public getGuests(customerId: string, payload: any): Observable<GuestAnswer> {
    const url = `${environment.followupPath}customers/${customerId}/guest-answers`;

    const params: HttpParams = new HttpParams()
      .set('start_date', payload.dates.initDate)
      .set('end_date', payload.dates.endDate)
      .set('product_id_list', payload.productId)
      .set('surveys', payload.surveys)
      .set('paged', payload.paged)
      .set('page_size', payload.page_size || 10)
      .set('page', payload.page || 0)
      .set('column_name', payload.column_name || null)
      .set('sort', payload.sort || null)
      .set('cases', payload.cases || null)
      .set('sources', payload.sources || '')
      .set('managed', payload.managed || null)
      .set('countries', payload.countries || '')
      .set('languages', payload.languages || '')
      .set('nps', payload.nps || '')
      .set('origins', payload.origins || '')
      .set('rooms', payload.rooms || '')
      .set('guest_ids', payload.guest_ids || '');

    return this.http.get<IHTTPResponse>(url, { params }).pipe(map((response: IHTTPResponse) => response.data));
  }

  getShareLink(answerId, languageId): Observable<{ internal: string; guest: string; pdf: string }> {
    const url = `${environment.surveysPath}answereds/${answerId}/share`;
    const params = new HttpParams().set('language_id', languageId);
    return this.http.get<{ internal: string; guest: string; pdf: string }>(url, {
      params,
    });
  }
}
