import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AutoCaseConfig } from './auto-case-config.model';
import { StoreCurrentAutoCaseConfig } from './auto-case-config.actions';
import { ConfigCasesService } from 'app/cases/services/config-cases.service';
import { Injectable } from '@angular/core';

export interface CustomerModel {
  currentCustomer: any;
  fullCurrentChain: any[];
  relativeCurrentChain: any[];
}

@State<AutoCaseConfig>({
  name: 'autoCaseConfig',
  defaults: {
    autoCaseConfig: [],
  }
})
@Injectable()
export class AutoCaseState {

  @Selector()
  static currentAutoCaseConfig(state: AutoCaseConfig) {
    return state.autoCaseConfig;
  }

  constructor(private configCasesService: ConfigCasesService) {}


  @Action(StoreCurrentAutoCaseConfig)
  storeCurrentAutoCaseConfig(context: StateContext<AutoCaseConfig>, action: { customerId: string }) {
    this.configCasesService.getAutocaseConfig(action.customerId).subscribe((data) => {
      context.patchState({ autoCaseConfig: data });
    });
  }
}
