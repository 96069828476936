import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'checkDateDiff'
})
export class CheckDateDiffDatepickerPipe implements PipeTransform {

  transform(value: DateTime, ...args: any[]): unknown {
    const currentDate = value;
    const selectedDate: DateTime = args[0];
    const hoveredDate: DateTime = args[1];
    const finalDate: DateTime = args[2];
    let result = false;
    
    if ( currentDate && selectedDate && !hoveredDate ) {
      return currentDate.day === selectedDate.day &&
        currentDate.month === selectedDate.month && 
        currentDate.year === selectedDate.year;
    }
    if (currentDate && selectedDate && hoveredDate) {
      result = (
        currentDate >= selectedDate &&
        currentDate <= hoveredDate
      )
      if ( currentDate.month < selectedDate.month && currentDate.year < selectedDate.year ) {
        result = false;
      }

      if (finalDate && currentDate.day === finalDate.day && currentDate.month === finalDate.month && currentDate.year === finalDate.year) {
        result = false;
      }
    }
    return result;
  }

}
